<template>
  <v-container class="grey lighten-5 mb-6" fluid>
    <v-row>
      <v-col>
        <v-card class="mx-auto form-card-padding">
          <v-toolbar flat>
            <v-toolbar-title class="grey--text">
              {{ $t('user.add.title') }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider></v-divider>

          <v-form ref="userForm" lazy-validation>
            <v-text-field
              v-model="userData.name"
              :counter="15"
              :rules="validation.nameRules"
              :label="$t('user.add.name')"
              required
            ></v-text-field>

            <v-text-field
              v-model="userData.lastname"
              :counter="15"
              :rules="validation.lastnameRules"
              :label="$t('user.add.lastname')"
              required
            ></v-text-field>

            <v-text-field
              v-model="userData.email"
              :rules="validation.emailRules"
              :label="$t('user.add.email')"
              required
            ></v-text-field>

            <v-text-field
              v-model="userData.password"
              :rules="validation.passwordRules"
              :label="$t('user.add.password')"
              required
            ></v-text-field>

            <v-select
              v-model="userData.type"
              :items="userTypes"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('user.add.type.label')"
              :hint="$t('user.add.type.hint')"
              persistent-hint
              item-value="value"
              item-text="label"
            ></v-select>

            <v-select
              style="margin-top: 10px"
              v-model="userData.vendor_id"
              :items="vendors"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('user.add.vendor.label')"
              :hint="$t('user.add.vendor.hint')"
              persistent-hint
              item-value="id"
              item-text="name"
            ></v-select>

            <v-btn
              style="margin-top: 10px"
              color="success"
              class="mr-4 align-self-lg-end"
              @click="createUser()"
            >
            {{ $t('user.add.controlls.create') }}
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <error-handler :error="error"></error-handler>
  </v-container>
</template>
<script>
export default {
  name: "CreateUser",
  data() {
    return {
      error: null,
      isLoading: true,
      validation: {
        nameRules: [
          (v) => !!v || "Name is required",
          (v) =>
            (v && v.length <= 10) || "Name must be less than 15 characters",
        ],
        lastnameRules: [
          (v) => !!v || "Lastname is required",
          (v) =>
            (v && v.length <= 10) || "Lastname must be less than 15 characters",
        ],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        passwordRules: [
          (v) => !!v || "Password is required",
          (v) =>
            (v && v.length > 5) || "Passwrod must be more than 5 characther",
        ],
      },
      userData: {
        name: "",
        lastname: "",
        email: "",
        password: "",
        type: "",
        vendor_id: null,
      },
    };
  },
  computed: {
    applicationData() {
      return this.$store.state.applicationData
    },
    userTypes() {
      if(this.applicationData) {
        return this.applicationData.user_types
      }
      return []
    },
    vendors() {
      if(this.applicationData) {
        return this.applicationData.vendors
      }
      return []
    }
  },
  mounted() {
    this.$store.dispatch("loadApplicationData")
  },
  methods: {
    createUser() {
      this.$axios({
        method: "post",
        url: this.$globals.api.user.create,
        data: this.userData,
      })
        .then(() => {
          this.$router.push({ name: "user/list" });
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
  },
};
</script>